.footer {
  background-color: #161c1c;

  .footerLogo {
    width: 46.13vw;
    max-width: 257px;
    margin-top: 64px;
    margin-left: 6.4vw;
    margin-bottom: 64px;
    max-width: 275px;
  }

  .footerFlex {
    display: flex;
    width: 59.73vw;
    margin-left: 6.4vw;

    .footerIcon {
      margin-right: clamp(10px, calc(59.73vw / 4 - 8.53vw), 24px);
      width: 8.53vw;
      height: 8.533vw;
      max-width: 40px;
      max-height: 40px;
    }
  }

  .footerText1 {
    font-size: 16px;
    margin-left: 6.4vw;
    margin-top: 40px;
  }

  .footerText2 {
    font-size: 16px;
    margin-left: 6.4vw;
    margin-top: 22px;
  }

  .footerText4 {
    font-size: 16px;
    font-family: 'KiwiMaru';
    margin-left: 6.4vw;
    margin-top: 40px;
    padding-bottom: 40px;
  }
}

@mixin responsive($property, $figma) {
  // MEMO:
  // figmaのデザインからレスポンス対応した要素size(px)を計算します。
  // 引数1：styleのプロパティ(width, padding-top, font-sizeなどから一つ指定)
  // 引数2：figmaデザインでのpx値の配列(スマホ(375px)、タブレット(834px)、PC(1440px))
  // 使用例) (scss内)
  // .className {
  //   @include responsive(
  //     $property: width,
  //     $figma: (100,200,500),
  //   );
  // }
  // →　画面幅375pxで100px、画面幅834pxで200px、画面幅1440pxで500px、になるようにwidthが変化

  $size375: nth($figma, 1);
  $size834: nth($figma, 2);
  $size1440: nth($figma, 3);

  #{$property}: calc($size375 / 375 * 100vw);

  @media (min-width: 375px) {
    #{$property}: calc(($size834 - $size375) * 100vw / (834 - 375) + ($size375 * 834 - $size834 * 375) * 1px / (834 - 375));
  }

  @media (min-width: 834px) {
    #{$property}: calc(($size1440 - $size834) * 100vw / (1440 - 834) + ($size834 * 1440 - $size1440 * 834)* 1px / (1440 - 834));
  }

  @media (min-width: 1440px) {
    #{$property}: calc($size1440 / 1440 * 100vw);
  }
}
@use '../../utils/LPCommon.module.scss';
@import '../../../zz_general/utils/scss-modules/mixin.module.scss';

.reservationsContents {
  @include responsive(
    $property: width,
    $figma: (
      340,
      512,
      720,
    )
  );
  text-align: left;
  margin: 0 auto;

  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  .title {
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
    line-height: 100%;
    text-align: left;
  }

  .text {
    @include responsive(
      $property: font-size,
      $figma: (
        12,
        14,
        16,
      )
    );
    @include responsive(
      $property: line-height,
      $figma: (
        16,
        18,
        20,
      )
    );
  }

  .lineS {
    border: 1px solid #c4c4c4;
    @include responsive(
      $property: margin-top,
      $figma: (
        8,
        9,
        10,
      )
    );
  }

  .lineM {
    border: 1px solid #6b757e;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .cardNum {
    display: flex;
    @include responsive(
      $property: margin-top,
      $figma: (
        8,
        9,
        10,
      )
    );

    .cardIcon {
      // display: block;
      @include responsive(
        $property: height,
        $figma: (
          16,
          18,
          20,
        )
      );
      @include responsive(
        $property: margin-right,
        $figma: (
          16,
          18,
          20,
        )
      );
    }

    .cardFlex {
      display: flex;

      .cardBrand {
        display: block;
        @include responsive(
          $property: height,
          $figma: (
            24,
            28,
            32,
          )
        );
        @include responsive(
          $property: margin-right,
          $figma: (
            8,
            9,
            10,
          )
        );
        @include responsive(
          $property: margin-top,
          $figma: (
            8,
            9,
            10,
          )
        );
      }

      .cardText {
        display: block;
        @include responsive(
          $property: font-size,
          $figma: (
            12,
            14,
            16,
          )
        );
        @include responsive(
          $property: line-height,
          $figma: (
            24,
            28,
            32,
          )
        );
        @include responsive(
          $property: height,
          $figma: (
            24,
            28,
            32,
          )
        );
        @include responsive(
          $property: margin-top,
          $figma: (
            8,
            9,
            10,
          )
        );
      }
    }
  }

  .payPay {
    display: block;
    @include responsive(
      $property: margin-top,
      $figma: (
        8,
        9,
        10,
      )
    );
    @include responsive(
      $property: margin-left,
      $figma: (
        8,
        9,
        10,
      )
    );
    @include responsive(
      $property: height,
      $figma: (
        24,
        26,
        28,
      )
    );
  }

  .checkBoxWrap {
    display: flex;
    align-items: flex-end;
    @include responsive(
      $property: font-size,
      $figma: (
        12,
        14,
        16,
      )
    );
    @include responsive(
      $property: line-height,
      $figma: (
        24,
        26,
        28,
      )
    );

    .checkBox {
      display: block;
      @include responsive(
        $property: height,
        $figma: (
          24,
          26,
          28,
        )
      );
      @include responsive(
        $property: margin-right,
        $figma: (
          24,
          28,
          32,
        )
      );
    }
  }

  .linkButton {
    @include responsive(
      $property: margin-top,
      $figma: (
        24,
        28,
        32,
      )
    );
  }
}

@media (max-width: 350px) {
  .reservations {
    .logo,
    .text,
    .frontCover {
      margin: 24px;
    }

    .reservationsContents {
      margin: 24px;
    }

    .frontCover {
      width: calc(90% - 48px);
    }
  }
}

// modalRechallenge.module.scss
body.active-modal {
    overflow-y: hidden;
  }
  .modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  
  .overlay {
    background: rgba(49,49,49,0.8);
    z-index: 999;
  }

  .modal{
    z-index: 1000;
  }
  
  .modal_content {
    z-index: 1001;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 28px 28px;
    max-width: 600px;
    min-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    border: 4px solid #6B757E;
    background: #FFF;
    color: #000;
    font-family: "Kiwi Maru";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal_text1{
    text-align: left;
    justify-content: left;
    padding-bottom: 10px;
  }
  .modal_text2{
    text-align: left;
    justify-content: left;
    padding-bottom: 30px;
  }
  .modal_button {
    color: #5f2f17;
    text-align: center;
    font-family: 'Kiwi Maru';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  
  .submitContainer {
    width: 320px;
    height: 80px;
    border-radius: 20px;
    border: 10px solid #5F2F17;
    background: #F0DCAA;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    cursor: pointer;
  }

@import '../../zz_general/utils/scss-modules/mixin.module.scss';

.readQR {
  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  .reserveInfoCon {
    @include responsive($property: padding, $figma: (24, 32, 40), );
    @include responsive($property: padding-left, $figma: (24, 100, 200), );
    @include responsive($property: padding-right, $figma: (24, 100, 200), );

    .reserveID {
      text-align: center;
      @include responsive($property: font-size, $figma: (16, 20, 24), );
      @include responsive($property: margin-top, $figma: (24, 32, 40), );
    }

    .content {
      text-align: center;
      @include responsive($property: font-size, $figma: (18, 24, 28), );
      @include responsive($property: margin-top, $figma: (24, 32, 40), );
    }

    .itemList {
      display: flex;
      justify-content: space-between;
      @include responsive($property: margin-top, $figma: (12, 16, 20), );

      .itemName {
        @include responsive($property: font-size, $figma: (16, 20, 24), );
      }

      .itemAmount {
        font-weight: bold;
        @include responsive($property: font-size, $figma: (18, 24, 28), );
      }
    }

    .statusTitle {
      text-align: center;
      @include responsive($property: font-size, $figma: (18, 24, 28), );
      @include responsive($property: margin-top, $figma: (24, 32, 40), );
    }

    .status {
      text-align: center;
      @include responsive($property: font-size, $figma: (18, 24, 28), );
      @include responsive($property: margin-top, $figma: (16, 20, 24), );
    }

    .tradeDone {
      background-color: black;
      color: white;
      border: 1px solid black;

      &:active {
        background-color: white;
        color: black;
        border: 1px solid black;
      }

      cursor: pointer;
      @include responsive($property: border-radius, $figma: (4, 6, 8), );
      @include responsive($property: width, $figma: (375 - 48, 834 - 200, 1440 - 400), );
      @include responsive($property: font-size, $figma: (16, 20, 24), );
      @include responsive($property: padding, $figma: (8, 10, 12), );
      @include responsive($property: margin-top, $figma: (24, 32, 36), );
    }

    .tradeCancel {
      background-color: white;
      color: black;
      border: 1px solid black;


      &:active {
        background-color: black;
        color: white;
        border: 1px solid black;
      }

      cursor: pointer;
      @include responsive($property: border-radius, $figma: (4, 6, 8), );
      @include responsive($property: width, $figma: (375 - 48, 834 - 200, 1440 - 400), );
      @include responsive($property: font-size, $figma: (16, 20, 24), );
      @include responsive($property: padding, $figma: (8, 10, 12), );
      @include responsive($property: margin-top, $figma: (18, 24, 28), );
    }
  }

  .scanQRButton {
    background-color: black;
    color: white;
    border: 1px solid black;

    &:active {
      background-color: white;
      color: black;
      border: 1px solid black;
    }

    cursor: pointer;
    @include responsive($property: border-radius, $figma: (4, 6, 8), );
    @include responsive($property: width, $figma: (375 - 48, 834 - 200, 1440 - 400), );
    @include responsive($property: font-size, $figma: (16, 20, 24), );
    @include responsive($property: padding, $figma: (8, 10, 12), );
    @include responsive($property: margin-bottom, $figma: (16, 20, 24), );
    @include responsive($property: margin-top, $figma: (18, 24, 28), );
    @include responsive($property: margin-left, $figma: (24, 100, 200), );
    @include responsive($property: margin-right, $figma: (24, 100, 200), );
  }

  .qrScanner {
    &BackGround {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
    }

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include responsive($property: width, $figma: (360, 480, 600), );

    .video {
      border-radius: 4px;
      width: 100%;
    }

    .cancelButton {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      border-radius: 50%;
      @include responsive($property: height, $figma: (24, 32, 36), );
      @include responsive($property: width, $figma: (24, 32, 36), );
    }
  }
}

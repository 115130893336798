@import '../../zz_general/utils/scss-modules/mixin.module.scss';
@import '../../zz_general/utils/scss-modules/common.module.scss';

.header {
  .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;

    .logo {
      width: 60vw;

      @media (min-width: 1200px) {
        height: 80px;
      }
    }
  }
}

.singleItemPanel {
  width: 100%;
}

.doubleItemPanel {
  width: 50%;
}

.itemPanel {
  flex: 1 p {
    margin: 0;
  }

  .imageBox {
    width: 100%;

    img {
      display: block;
      margin: 0 auto;
      width: auto;
      max-width: 100%;
      height: auto;
    }

    @media (min-width: 899.9px) {
      max-height: 320px;
    }
  }

  .singleItemImage {
    img {
      max-height: 170px;
      width: auto;

      @media (min-width: 899.9px) {
        max-height: 305px;
      }
    }
  }

  .doubleItemImage {
    img {
      max-height: 150px;
      width: auto;

      @media (min-width: 899.9px) {
        max-height: 320px;
      }
    }
  }

  .textBox {
    font-weight: bold;

    .name {
      font-size: clamp(11px, 2.9vw, 18px);
    }

    .price {
      font-size: clamp(11px, 2.9vw, 18px);
      text-align: right;
    }
  }

  .actionBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    .countBox {
      display: flex;

      .minusButton {
        width: 24px;
        height: 24px;
      }

      .plusButton {
        width: 24px;
        height: 24px;
      }

      .itemCount {
        margin: 0 10px;
      }
    }

    .button {
      padding: 8px;
      font-size: 13px;
      text-align: center;
      color: white;
      background-color: #000000;
      border-radius: 4px;
      margin-left: 12px;
    }
  }
}

.sideMenu {
  .sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 272px;
    padding-left: 32px;
    transform: translateX(-304px); // widthとpadding-leftの合算値
    transition: transform 250ms ease-in-out;
    background-color: #fff;
    z-index: 10;

    @media (min-width: 899.9px) {
      position: fixed;
      transform: translateX(0);
    }
  }

  .sidebarMenuInner {
    margin: 0;
    padding: 0;
    margin-top: 56px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebarMenuInner li {
    list-style: none;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    p {
      margin-top: 32px;
      margin-bottom: 0;
      margin-right: 0;
      margin-left: 0;
      font-size: 16px;
    }
  }

  input[type='checkbox']:checked~.sidebarMenu {
    transform: translateX(0);
  }

  input[type='checkbox'] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
  }

  .sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    z-index: 99;
    top: 20px;
    left: 16px;
    height: 32px;
    width: 32px;

    @media (min-width: 899.9px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: #916755;
  }

  .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }
}

// MEMO: Cart
.addbuttonCon {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.addbutton {
  height: 19px;
  width: 19px;
}

.ItemPanel_flex2 {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.ItemPanelSetContents {
  font-family: 'KiwiMaru';
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
}

.setContentsText {
  font-family: 'KiwiMaru';
  margin-top: 4px;
  line-height: 20px;
}

.CartModal {
  z-index: 10;
  font-family: 'KiwiMaru', 'NotoSanJP';
  font-weight: 700;
  color: #161c1c;
  background-color: #ffffff;
  max-width: 600px;
  width: 80vw;
  max-height: clamp(64vh, calc(61.81vw + 228.18px), 90vh);
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px;

  .ModalBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Modal {
    z-index: 10;
    font-family: 'KiwiMaru', 'NotoSanJP';
    color: #161c1c;
    background-color: #ffffff;
    max-width: 600px;
    width: 80vw;
    max-height: clamp(64vh, calc(61.81vw + 228.18px), 90vh);
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 4px;
    overflow-y: scroll;

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    position: fixed;

    @media (min-width: 899.9px) {
      left: calc(50vw + 150px);
    }

    .topContent {
      height: 80px;
      display: flex;
      align-items: center;

      button {
        margin: 24px;
      }

      .cartLogo {
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }
    }

    .bottomContent {
      width: 100%;
      padding: 16px 0;
      background-color: #f3f4f5;
      position: relative;

      .fee {
        background-color: #ffffff;
        padding: 16px 24px;

        .postage {
          text-align: end;

          font-size: 24px;
          margin-right: 22px;

          .span1 {
            font-family: 'KiwiMaru';
            font-size: 12px;
            font-weight: 100;
          }

          .span2 {
            font-family: 'KiwiMaru';
            font-size: 16px;
          }

          .span3 {
            font-family: 'KiwiMaru';
            font-size: 24px;
          }

          .span4 {
            font-family: 'KiwiMaru';
            font-size: 12px;
            font-weight: 100;
          }
        }

        .cart_icon {
          @media (max-width: 899.9px) {
            width: 30px;
            height: auto;
          }

          @media (max-width: 260px) {}
        }

        .priceFont {
          font-family: 'KiwiMaru';
          font-size: 12px;
          margin-right: 4px;
          color: #6b757e;
        }

        .priceFont2 {
          font-family: 'NotoSanJP';
          color: #22292f;
          font-size: 24px;
          margin-bottom: -2px;
        }

        .priceFont3 {
          font-family: 'NotoSanJP';
          font-size: 32px;
          color: #22292f;
          margin-right: 4px;
          margin-bottom: -6px;
        }

        .priceFont4 {
          font-family: 'KiwiMaru';
          font-size: 12px;
          margin-right: 4px;
          color: #22292f;
        }
      }

      .addTextBox {
        background-color: #f3f4f5;
        font-size: 12px;
        padding: 16px 0;
        text-align: center;
        justify-content: space-between;
      }

      .purchase {
        padding: 0 22px;

        .continue {
          border: solid 1px #000000;
          border-radius: 4px;
        }
      }

      .upSellBox {
        position: relative;
        width: 100%;

        &.open {
          height: 100%;
        }

        &.close {
          max-height: 125px;
        }

        .upCellItems {
          overflow: hidden;

          &.open {
            height: 100%;
          }

          &.close {
            max-height: 170px;
          }
        }
      }

      .upCellItem {
        display: flex;
        justify-content: space-between;
        background-color: #ffffff;
        height: 85px;
        padding-top: 16px;
        padding-bottom: 8px;
        padding-left: 8px;
        margin-right: 22px;
        margin-left: 22px;
        margin-bottom: 16px;
        border-radius: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        .textBox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 50%;

          @media (max-width: 899.9px) {
            width: 70%;
          }

          .name {
            font-size: clamp(10px, calc(1.14vw + 7.71px), 20px);
          }

          .price {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .box {
          display: flex;
          justify-content: flex-end;
          width: 50%;
          padding-right: 8px;

          .imgBox {
            margin-right: 20px;
            position: relative;
            width: 150px;
            height: 100px;
            overflow: hidden;

            @media (max-width: 899.9px) {
              height: 67px;
              width: 101px;
              margin-right: 0px;
            }
          }

          .img {
            max-width: 100%;
            height: auto;
          }

          .addTextBox {
            align-self: center;
            width: 50%;
            background-color: #ffffff;

            @media (max-width: 899.9px) {
              display: none;
            }

            .text {
              color: white;
              background-color: #161c1c;
              margin: 0 5px;
              padding: 10px;
              border-radius: 4px;
            }
          }
        }

        .box {
          display: flex;
          justify-content: flex-end;
          width: 50%;
          padding-right: 8px;

          .imgBox {
            margin-right: 20px;
            position: relative;
            width: 150px;
            height: 100px;
            overflow: hidden;

            @media (max-width: 899.9px) {
              height: 67px;
              width: 101px;
              margin-right: 0px;
            }
          }

          .img {
            max-width: 100%;
            height: auto;
          }

          .addTextBox {
            align-self: center;
            width: 50%;
            background-color: #ffffff;

            @media (max-width: 899.9px) {
              display: none;
            }

            .text {
              color: white;
              background-color: #161c1c;
              margin: 0 5px;
              padding: 10px;
              border-radius: 4px;
            }
          }
        }
      }

      .isSetShow {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff 60%);
        position: relative;
        text-align: center;
        padding-top: 35px;
        padding-bottom: 18px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .choose {
      padding: 0 22px;
    }
  }

  .cartContent {
    width: 100%;
    text-align: center;
    background-color: #f3f4f5;
    padding-top: 12px;
    padding-bottom: 0;
    overflow: scroll;

    &.items {
      height: 100%;
    }

    .cartLogo {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    .CartItem {
      background-color: #ffffff;
      display: flex;
      border-radius: 6px;
      justify-content: space-between;
      font-weight: 400;
      margin-right: 24px;
      margin-left: 24px;
      margin-bottom: 16px;
      padding-top: 8px;
      padding-right: 8px;
      padding-left: 8px;
      padding-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .item_font1 {
        text-align: left;
        font-size: clamp(10px, calc(1.14vw + 7.71px), 20px);
        font-weight: bold;
      }

      .item_font2 {
        text-align: left;
        font-weight: bold;
        font-size: clamp(14px, calc(0.4vw + 14.57px), 20px);

        @media (max-width: 899.9px) {
          position: absolute;
          bottom: clamp(0px, calc(5.71vw - 21.43px), 30px);
        }

        @media (min-width: 900px) {
          padding-top: 18px;
        }
      }

      .item_img_con1 {
        margin: auto;
        position: relative;
        height: 67px;
        width: 101px;
        max-width: 150px;
        max-height: 100px;
        overflow: hidden;

        @media (min-width: 900px) {
          display: none;
        }

        .item_img {
          height: 67px;
          width: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .item_img_con2 {
        @media (max-width: 899.9px) {
          display: none;
        }

        margin-right: 20px;
        position: relative;
        width: 150px;
        height: 100px;
        overflow: hidden;

        .item_img {
          height: 100px;
          width: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .buttons {
        margin: auto;
        display: flex;
        justify-content: space-around;

        @media (max-width: 899.9px) {
          width: 100px;
        }
      }

      .buttonsContent {
        margin-top: 8px;
        display: flex;
        align-items: center;
        width: 100px;
        justify-content: space-between;

        .buttonsBox {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
          }
        }
      }

      .ul {
        margin: auto;
        display: flex;
        justify-content: space-around;

        @media (max-width: 899.9px) {
          max-width: 150px;
        }
      }
    }
  }
}

.customerInfoSection {
  .title {
    @include responsive($property: font-size,
      $figma: (16,
        18,
        20,
      ));
    line-height: 100%;
    text-align: left;
  }

  .customerInfo {
    display: flex;
    align-items: flex-end;

    * {
      box-sizing: border-box;
      border: 0;
      margin: 0;
      padding: 0;
      font-family: 'KiwiMaru', 'NotoSanJP';
    }

    .text {
      @include responsive($property: font-size,
        $figma: (12,
          14,
          16,
        ));
      @include responsive($property: line-height,
        $figma: (16,
          18,
          20,
        ));
      display: flex;
      @include responsive($property: margin-top,
        $figma: (8,
          9,
          10,
        ));
    }

    img {
      display: block;
      @include responsive($property: height,
        $figma: (16,
          18,
          20,
        ));
      @include responsive($property: margin-right,
        $figma: (16,
          18,
          20,
        ));
    }
  }

  .address {
    @include responsive($property: font-size,
      $figma: (12,
        14,
        16,
      ));
    @include responsive($property: line-height,
      $figma: (16,
        18,
        20,
      ));
    display: block;
    @include responsive($property: margin-top,
      $figma: (8,
        9,
        10,
      ));
    @include responsive($property: margin-left,
      $figma: (32,
        36,
        40,
      ));
  }
}

.orderDetailsSection {
  @include responsive($property: width,
    $figma: (340,
      512,
      720,
    ));
  text-align: left;
  margin: 0 auto;

  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  .title {
    @include responsive($property: font-size,
      $figma: (16,
        18,
        20,
      ));
    line-height: 100%;
    text-align: left;
  }

  .text {
    @include responsive($property: font-size,
      $figma: (12,
        14,
        16,
      ));
    @include responsive($property: line-height,
      $figma: (16,
        18,
        20,
      ));
  }

  .lineS {
    border: 1px solid #c4c4c4;
    @include responsive($property: margin-top,
      $figma: (8,
        9,
        10,
      ));
  }

  .productList {
    display: flex;
    justify-content: space-between;
    @include responsive($property: margin-top,
      $figma: (8,
        9,
        10,
      ));

    .productName {
      max-width: 50%;
    }

    .price {
      font-weight: bold;
    }
  }
}

.errModal {
  z-index: 10;
  font-family: 'KiwiMaru', 'NotoSanJP';
  font-weight: 700;
  color: #161c1c;
  background-color: #ffffff;
  max-width: 600px;
  width: 80vw;
  max-height: clamp(64vh, calc(61.81vw + 228.18px), 90vh);
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px;

  .ModalBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Modal {
    z-index: 10;
    font-family: 'KiwiMaru', 'NotoSanJP';
    color: #161c1c;
    background-color: #ffffff;
    max-width: 600px;
    width: 80vw;
    max-height: clamp(64vh, calc(61.81vw + 228.18px), 90vh);
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 4px;
    overflow-y: scroll;

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: fixed;

    border-top: solid 6px #ff5656;

    @media (min-width: 899.9px) {
      left: calc(50vw + 150px);
    }
  }

  .iconBox {
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .deleteIcon {
      width: 100%;
      height: auto;
    }
  }

  .textBox {
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;

    .title {
      font-size: 16px;
      color: #22292f;
      font-weight: bold;
    }

    .description {
      font-size: 12px;
      color: #22292f;
    }
  }

  .buttonBox {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    margin-left: 16px;
    margin-right: 16px;

    button {
      font-size: 16px;
      font-weight: bold;
      padding: 16px;
      padding-bottom: 16px;
      text-align: center;
    }

    .deleteButton {
      color: #ffffff;
      background-color: #ff5656;
    }

    .cancelButton {
      color: black;
    }
  }

  padding: 24px;
}
.shopPage {
  background-color: #4b3930;
  color: white;
}

.body {
  margin-top: clamp(0px, 25vw, 0px);
  padding: 64px calc(40 / 375 * 100vw) 0;
  text-align: center;
  img {
    width: 100%;
  }
  .title {
    font-size: clamp(0px, calc(24 / 375 * 100vw), calc(24 / 375 * 900px));
    margin-bottom: 40px;
  }

  .sec1 {
    margin-bottom: 64px;
  }
  .sec2 {
    margin-bottom: 40px;
    .spread {
      z-index: 9;
      position: fixed;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      animation-name: spreadAnimation;
      animation-fill-mode: forwards;
      animation-duration: 0.9s;
      animation-iteration-count: 1;
      animation-timing-function: ease;
      animation-delay: 0s;
      animation-direction: normal;
    }
    @keyframes spreadAnimation {
      0% {
        width: 80vw;
        max-width: calc(var(--menuWidth) / var(--menuHeight) * 80vh);
        left: 50%;
        top: 50%;
      }
      100% {
        max-width: calc(var(--menuWidth) / var(--menuHeight) * 100vh);
        left: 50%;
        top: 50%;
      }
    }
  }
  .sec3 {
    margin-bottom: 40px;
    .sec3flex {
      display: flex;
      justify-content: space-between;
      width: calc(262 / 295 * 100%);
      margin: 0 auto;
      .box1 {
        width: calc(102 / 262 * 100%);
        img {
          width: calc(80 / 102 * 100%);
        }
        p {
          margin: 8px 0 0;
          font-size: clamp(0px, calc(16 / 375 * 100vw), calc(16 / 375 * 900px));
        }
      }
      .box2 {
        width: calc(102 / 262 * 100%);
        img {
          width: calc(80 / 102 * 100%);
        }
        p {
          margin: 8px 0 0;
          font-size: clamp(0px, calc(16 / 375 * 100vw), calc(16 / 375 * 900px));
        }
      }
    }
    .sec3flex2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: calc(24 / 375 * 100vw);
        height: calc(24 / 375 * 100vw);
      }
      p {
        margin: 16px 0 7px 0;
        font-size: clamp(0px, calc(16 / 375 * 100vw), calc(16 / 375 * 900px));
      }
    }
  }
  .sec4 {
    margin-bottom: 40px;
    .map {
      width: 75vw;
      height: 50vw;
    }
  }
  .sec5 {
    margin-bottom: 40px;
    .twitter_timeline {
      height: 100vh;
    }
    .review {
      margin-bottom: 30px;
    }
  }
}

.dNone {
  display: none;
}

.blackCover {
  z-index: 8;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.7);
  width: 100vw;
  height: 100vh;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.crossIcon {
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 1);
  color: white;
  position: fixed;
  right: 1vw;
  top: 1vw;
  font-size: 5vw;
  line-height: 5vw;
  width: 5vw;
  border-radius: 4px;
  padding: 1vw;
}

.landingPageLink {
  --button_width: clamp(0px, 25vw, 160px);
  .cartButton {
    border-radius: 4px;
    top: 0;
    position: fixed;
    right: 0;
    width: var(--button_width);
    height: var(--button_width);
    z-index: 5;
    background-color: #161c1c;
    .cartIcon {
      width: 36%;
      display: block;
      margin: 0 auto;
      margin-top: 18%;
      margin-bottom: 4%;
    }
    .buttonText {
      color: white;
      text-align: center;
      font-size: calc(var(--button_width) * 0.18);
      font-weight: bold;
    }
  }
}

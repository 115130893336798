a,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}

input::-webkit-input-placeholder {
  font-family: 'KiwiMaru', 'NotoSanJP';
}
input:-moz-placeholder {
  font-family: 'KiwiMaru', 'NotoSanJP';
}
input::-moz-placeholder {
  font-family: 'KiwiMaru', 'NotoSanJP';
}
input:-ms-input-placeholder {
  font-family: 'KiwiMaru', 'NotoSanJP';
}

input[type='tel' i]::-webkit-input-placeholder {
  font-family: 'NotoSanJP';
}
input[type='tel' i]:-moz-placeholder {
  font-family: 'NotoSanJP';
}
input[type='tel' i]::-moz-placeholder {
  font-family: 'NotoSanJP';
}
input[type='tel' i]:-ms-input-placeholder {
  font-family: 'NotoSanJP';
}

.bcC4C4C4 {
  background-color: #c4c4c4;
}

.bc161c1c {
  background: #161c1c;
}

body {
  margin: 0;
  font-family: 'KiwiMaru', 'NotoSanJP';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  appearance: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.displayFlex {
  display: flex;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}

.displayNone {
  display: none;
}

.display_true {
  display: block;
}
.display_false {
  display: none;
}

.ht100vh {
  height: 100vh;
}

.hori50 {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ma {
  margin: 0 auto;
}

.mb2_5_m {
  margin-bottom: -2.5px;
}
.mb4 {
  margin-bottom: 4px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}
.mb40 {
  margin-bottom: 40px;
}

.minWid100 {
  min-width: 100%;
}

.ml4 {
  margin-left: 4px;
}

.ml12 {
  margin-left: 12px;
}

.ml16 {
  margin-left: 16px;
}

.ml24 {
  margin-left: 24px;
}

.ml48 {
  margin-left: 48px;
}

.ml36 {
  margin-left: 36px;
}

.ml60 {
  margin-left: 60px;
}

.mr12 {
  margin-right: 12px;
}

.mr16 {
  margin-right: 16px;
}

.mt2_m {
  margin-top: -2px;
}

.mt5 {
  margin-top: 5px !important;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt64 {
  margin-top: 64px;
}

.ofHid {
  overflow: hidden;
}

.pr8 {
  padding-right: 8px;
}
.pb24 {
  padding-bottom: 24px;
}

.pb40 {
  padding-bottom: 40px;
}

.pd0_24 {
  padding: 0 24px;
}

.pd24_0 {
  padding: 24px 0;
}

.psAb {
  position: absolute;
}

.psRl {
  position: relative;
}

.pt24 {
  padding-top: 24px;
}

.txAC {
  text-align: center;
}

.txAL {
  text-align: left;
}

.txAR {
  text-align: right;
}

.vert50 {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertAlB {
  vertical-align: bottom;
}

.vertAlM {
  vertical-align: middle;
}

.wh24 {
  width: 24px;
  height: 24px;
}

.wh30 {
  width: 30px;
  height: 30px;
}

.wh36 {
  width: 36px;
  height: 36px;
}

.wid10 {
  width: 10%;
}

.wid20 {
  width: 20%;
}

.wid36 {
  width: 36px;
}

.wid50 {
  width: 50%;
}

.wid60 {
  width: 60%;
}

.wid70 {
  width: 70%;
}

.wid80 {
  width: 80%;
}

.wid90 {
  width: 90%;
}

.wid100 {
  width: 100%;
}

.hei100 {
  height: 100%;
}

.hei50 {
  height: 50%;
}

.hei150_0 {
  height: 1500px;
}

.fs14 {
  font-size: 14px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs24 {
  font-size: 24px;
}

.fs36 {
  font-size: 36px;
}

.fw_b {
  font-weight: bold;
}

.m_auto {
  margin: auto;
}

.fr {
  float: right;
}
.fl {
  float: left;
}

.disable {
  display: none;
}

.visible {
  display: block;
}

.inline {
  display: inline;
}

.fontTaFugaFude {
  font-family: 'ta-fuga-fude', sans-serif !important;
}

.colorRed {
  color: red;
}

.BoxButton {
  font-family: 'KiwiMaru';
  height: 68px;
  background: #161c1c;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  position: relative;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.fontJp {
  font-family: 'KiwiMaru';
}

.fontRome {
  font-family: 'NotoSanJP';
}

.spInvisible {
  display: block;
  @media (max-width: 899.9px) {
    display: none;
  }
}

.pcInvisible {
  display: block;
  @media (min-width: 900px) {
    display: none;
  }
}

@font-face {
  font-family: 'GenShinGothic-Light';
  src: url(../../../../public/font/GenShinGothic-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'GenShinGothic-Medium';
  src: url(../../../../public/font/GenShinGothic-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'GenShinGothic-Normal';
  src: url(../../../../public/font/GenShinGothic-Normal.ttf) format('truetype');
}
@font-face {
  font-family: 'GenShinGothic-Bold';
  src: url(../../../../public/font/GenShinGothic-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'GenShinGothic-Heavy';
  src: url(../../../../public/font/GenShinGothic-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirLTStd-Light';
  src: url(../../../../public/font/AvenirLTStd-35-Light.otf) format('opentype');
}
@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url(../../../../public/font/AvenirLTStd-45-Book.otf) format('opentype');
}
@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url(../../../../public/font/AvenirLTStd-55-Roman.otf) format('opentype');
}
@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: url(../../../../public/font/AvenirLTStd-65-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'AvenirLTStd-Heavy';
  src: url(../../../../public/font/AvenirLTStd-85-Heavy.otf) format('opentype');
}
@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url(../../../../public/font/AvenirLTStd-95-Black.otf) format('opentype');
}
@font-face {
  font-family: 'KiwiMaru';
  src: url(../../../../public/font/KiwiMaru-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'NotoSanJP';
  src: url(../../../../public/font/NotoSansJP-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'NotoSanJP-Bold';
  src: url(../../../../public/font/NotoSansJP-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'digital-7';
  src: url(../../../../public/font/digital-7.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Han Sans JP-Bold';
  src: url(../../../../public/font/SourceHanSansHW-VF.otf) format('truetype');
}
@font-face {
  font-family: 'Source Han Sans JP';
  src: url(../../../../public/font/SourceHanSans-VF.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Han Sans JP-2';
  src: url(../../../../public/font/SourceHanSans-VF.otf) format('truetype');
}
@import '../../zz_general/utils/scss-modules/mixin.module.scss';

.signIn {
  @include responsive($property: padding, $figma: (24, 64, 64), );
  @include responsive($property: padding-right, $figma: (24, 100, 200), );
  @include responsive($property: padding-left, $figma: (24, 100, 200), );

  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  .title {
    text-align: center;
    font-weight: lighter;
    @include responsive($property: font-size, $figma: (24, 28, 32), );
    @include responsive($property: margin-bottom, $figma: (24, 28, 32), );
  }

  .inputLabel {
    display: flex;
    @include responsive($property: font-size, $figma: (16, 20, 24), );
    @include responsive($property: line-height, $figma: (16, 20, 24), );
    @include responsive($property: margin-bottom, $figma: (4, 8, 8), );

    .required {
      display: block;
      color: white;
      border-radius: 4px;
      background-color: #FF5656;
      @include responsive($property: font-size, $figma: (12, 16, 20), );
      @include responsive($property: line-height, $figma: (12, 16, 20), );
      @include responsive($property: padding, $figma: (2, 2, 2), );
      @include responsive($property: padding-right, $figma: (8, 8, 8), );
      @include responsive($property: padding-left, $figma: (8, 8, 8), );
      @include responsive($property: margin-left, $figma: (8, 8, 8), );
    }
  }

  .inputBox {
    background-color: #F3F4F5;
    @include responsive($property: border-radius, $figma: (4, 6, 8), );
    @include responsive($property: width, $figma: (375 - 48, 834 - 200, 1440 - 400), );
    @include responsive($property: font-size, $figma: (16, 20, 24), );
    @include responsive($property: padding, $figma: (8, 10, 12), );
    @include responsive($property: margin-bottom, $figma: (16, 20, 24), );
  }

  .loginButton {
    &Active {
      background-color: black;
      color: white;
      border: 1px solid black;
    }

    &Disabled {
      background-color: #C4C4C4;
      color: white;
      border: 1px solid #C4C4C4;
    }

    &Active:active {
      background-color: white;
      color: black;
      border: 1px solid black;
    }

    cursor: pointer;
    @include responsive($property: border-radius, $figma: (4, 6, 8), );
    @include responsive($property: width, $figma: (375 - 48, 834 - 200, 1440 - 400), );
    @include responsive($property: font-size, $figma: (16, 20, 24), );
    @include responsive($property: padding, $figma: (8, 10, 12), );
    @include responsive($property: margin-bottom, $figma: (16, 20, 24), );
    @include responsive($property: margin-top, $figma: (18, 24, 28), );
  }
}

@import '../../zz_general/utils/scss-modules/mixin.module.scss';

.menu {

  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  .header {
    display: flex;
    justify-content: space-between;
    @include responsive($property: padding-top, $figma: (24, 32, 36), );
    @include responsive($property: padding-right, $figma: (24, 100, 200), );
    @include responsive($property: padding-left, $figma: (24, 100, 200), );

    .menuIcon {
      cursor: pointer;
      @include responsive($property: width, $figma: (40, 44, 48), );
      @include responsive($property: height, $figma: (40, 44, 48), );
    }

    .titleWrap {
      display: flex;

      .logo {
        @include responsive($property: width, $figma: (40, 44, 48), );
        @include responsive($property: height, $figma: (40, 44, 48), );
      }

      .title {
        font-weight: bold;
        @include responsive($property: height, $figma: (40, 44, 48), );
        @include responsive($property: line-height, $figma: (40, 44, 48), );
        @include responsive($property: font-size, $figma: (24, 28, 32), );
        @include responsive($property: margin-left, $figma: (12, 14, 16), );
      }
    }

    .flexAdjust {
      @include responsive($property: width, $figma: (40, 44, 48), );
    }
  }

  .slideMenuBackGround {
    &Active {
      z-index: 1;
    }

    &Hidden {
      z-index: -1;
    }

    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .slideMenu {
    &Active {
      transition: transform 0.5s;
      transform: translateX(0);
    }

    &Hidden {
      transition: transform 0.5s;
      transform: translateX(-100%);
    }

    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: white;
    @include responsive($property: padding, $figma: (32, 40, 48), );
    @include responsive($property: padding-left, $figma: (24, 100, 200), );
    @include responsive($property: padding-right, $figma: (40, 220, 280), );
    @include responsive($property: margin-top, $figma: (40 + 24, 44 + 32, 48 + 36), );

    div {
      &:hover {
        color: gray;
      }

      &:active {
        background-color: #F3F4F5;
        color: gray;
      }

      cursor: pointer;
      @include responsive($property: font-size, $figma: (16, 20, 24), );
      @include responsive($property: margin-bottom, $figma: (16, 20, 24), );
    }
  }
}


.tiramisuIcon {
  z-index: 3;
  cursor: pointer;
  position: relative;
  @include responsive($property: width, $figma: (40, 44, 48), );

  @function calcHeight($valueIn375px) {
    @return ($valueIn375px, $valueIn375px * 44 / 40, $valueIn375px * 48 / 40);
  }

  @include responsive($property: height, $figma: calcHeight(24), );
  @include responsive($property: margin-top, $figma: calcHeight(8), );
  @include responsive($property: margin-bottom, $figma: calcHeight(8), );

  .firstLine {
    position: absolute;
    width: 100%;
    background-color: #522914;
    top: 0;
    @include responsive($property: height, $figma: calcHeight(4), );

    &Active {
      display: none;
    }
  }

  .secondLine {
    position: absolute;
    width: 100%;
    background-color: #F3DFAE;
    @include responsive($property: top, $figma: calcHeight(4), );
    @include responsive($property: height, $figma: calcHeight(6), );

    &Active {
      display: none;
    }
  }

  .thirdLine {
    position: absolute;
    width: 100%;
    background-color: #916755;
    @include responsive($property: top, $figma: calcHeight(10), );
    @include responsive($property: height, $figma: calcHeight(4), );

    &Active {
      transition: transform 0.5s;
      transform: rotate(-45deg);
      @include responsive($property: top, $figma: calcHeight(8), );
    }
  }

  .fourthLine {
    position: absolute;
    width: 100%;
    background-color: #F3DFAE;
    @include responsive($property: top, $figma: calcHeight(14), );
    @include responsive($property: height, $figma: calcHeight(6), );

    &Active {
      display: none;
    }
  }

  .fifthLine {
    position: absolute;
    width: 100%;
    background-color: #916755;
    @include responsive($property: top, $figma: calcHeight(20), );
    @include responsive($property: height, $figma: calcHeight(4), );

    &Active {
      transition: transform 0.5s;
      transform: rotate(45deg);
      @include responsive($property: top, $figma: calcHeight(8), );
    }
  }
}

// MEMO: pcは899.9px以上
.LPBodyBase {
  // LPの全画面共通のスタイル定義
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  font-family: 'KiwiMaru', 'NotoSanJP';
  p {
    margin: 0;
  }

  @media (min-width: 899.9px) {
    width: calc(100vw - 336px); // margin-leftの値 + sidebarMenuのmargin-leftの値
    margin-left: 304px;
  }
}

.eng {
  font-family: 'NotoSanJP';
}

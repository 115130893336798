@import '../../../zz_general/utils/scss-modules/mixin.module.scss';

.payment {
  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  margin: 0 auto;
  @include responsive($property: padding, $figma: (24, 40, 40), );

  .title {
    @include responsive($property: margin-bottom, $figma: (8, 9, 10), );
    @include responsive($property: margin-top, $figma: (16, 18, 20), );
    @include responsive($property: font-size, $figma: (16, 18, 20), );
  }

  .totalPrice {
    text-align: center;

    .total {
      color: #6B757E;
      @include responsive($property: font-size, $figma: (12, 14, 16), );
    }

    .yen {
      font-family: 'NotoSanJP';
      @include responsive($property: font-size, $figma: (24, 28, 32), );
    }

    .price {
      font-family: 'NotoSanJP';
      font-weight: bold;
      @include responsive($property: font-size, $figma: (32, 36, 40), );
    }

    .taxIncluded {
      color: #6B757E;
      @include responsive($property: font-size, $figma: (12, 14, 16), );
    }
  }

  .card {
    &Active {
      border: 1px solid black;
    }

    &Passive {
      border: 1px solid #C4C4C4;
    }

    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    @include responsive($property: border-radius, $figma: (4, 6, 8), );
    @include responsive($property: font-size, $figma: (16, 18, 20), );
    @include responsive($property: line-height, $figma: (16, 18, 20), );
    @include responsive($property: padding, $figma: (16, 18, 20), );

    .check {
      @include responsive($property: height, $figma: (16, 18, 20), );
    }
  }

  .payPay {
    &Active {
      border: 1px solid black;
    }

    &Passive {
      border: 1px solid #C4C4C4;
    }

    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    @include responsive($property: border-radius, $figma: (4, 6, 8), );
    @include responsive($property: padding, $figma: (12, 14, 16), );
    @include responsive($property: padding-left, $figma: (16, 18, 20), );
    @include responsive($property: padding-right, $figma: (16, 18, 20), );

    .payPayLogo {
      @include responsive($property: height, $figma: (24, 26, 28), );
    }

    .check {
      @include responsive($property: height, $figma: (16, 18, 20), );
      @include responsive($property: margin-top, $figma: (4, 4, 4), );
      @include responsive($property: margin-bottom, $figma: (4, 4, 4), );
    }
  }

  .linkButton {
    @include responsive($property: margin-top, $figma: (24, 28, 32), );
  }

  .display {
    &Active {
      display: block;
    }

    &Passive {
      display: none;
    }
  }
}

.cardIconWrap {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @include responsive($property: margin-top, $figma: (16, 18, 20), );

  .cardIcon {
    @include responsive($property: width, $figma: (60, 80, 100), );
  }
}

.cardInputForm {
  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  input {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  @function calcFromRatio($sizeIn375px) {
    $holeWidthIn375px: 240;
    $holeWidthIn834px: 360;
    $holeWidthIn1440px: 480;
    @return ($sizeIn375px, $sizeIn375px* $holeWidthIn834px / $holeWidthIn375px, $sizeIn375px* $holeWidthIn1440px / $holeWidthIn375px);
  }

  position: relative;
  margin: 0 auto;
  @include responsive($property: margin-top, $figma: (16, 18, 20), );
  @include responsive($property: height, $figma: (calcFromRatio(172)), );

  .formActive {
    background-color: #161C1C !important;
  }

  .formFocused {
    outline: 2px solid #C4C4C4 !important;
  }

  .face {
    position: absolute;
    z-index: 3;
    background-color: #161C1C;
    border-radius: 3%;
    @include responsive($property: width, $figma: (calcFromRatio(240)), );
    @include responsive($property: height, $figma: (calcFromRatio(154)), );
    @include responsive($property: padding, $figma: (calcFromRatio(16)), );

    .imgWrap {
      display: flex;
      justify-content: space-between;
      @include responsive($property: height, $figma: (calcFromRatio(34)), );

      .ic {
        display: block;
        @include responsive($property: width, $figma: (calcFromRatio(38)), );
      }

      .amex {
        @include responsive($property: height, $figma: (calcFromRatio(24)), );
      }

      .diners {
        @include responsive($property: height, $figma: (calcFromRatio(24)), );
      }

      .jcb {
        height: 100%;
      }

      .mastercard {
        height: 100%;
      }

      .visa {
        @include responsive($property: height, $figma: (calcFromRatio(16)), );
      }
    }


    .inputLabel {
      color: #FFFFFF;
      font-weight: lighter;
      @include responsive($property: height, $figma: (calcFromRatio(12)), );
      @include responsive($property: line-height, $figma: (calcFromRatio(12)), );
      @include responsive($property: font-size, $figma: (calcFromRatio(12)), );
      @include responsive($property: margin-top, $figma: (calcFromRatio(8)), );
    }

    .inputFlex {
      display: flex;
      justify-content: space-between;
    }

    .cardNum {
      background-color: #6B757E80;
      font-family: 'NotoSanJP';
      width: 100%;
      @include responsive($property: border-radius, $figma: (calcFromRatio(4)), );
      @include responsive($property: line-height, $figma: (calcFromRatio(16)), );
      @include responsive($property: padding, $figma: (calcFromRatio(4)), );
      @include responsive($property: height, $figma: (calcFromRatio(24)), );
    }

    .holdersName {
      display: block;
      background-color: #6B757E80;
      font-family: 'NotoSanJP';
      color: #C4C4C4;
      @include responsive($property: border-radius, $figma: (calcFromRatio(4)), );
      @include responsive($property: font-size, $figma: (calcFromRatio(12)), );
      @include responsive($property: line-height, $figma: (calcFromRatio(12)), );
      @include responsive($property: padding, $figma: (calcFromRatio(6)), );
      @include responsive($property: height, $figma: (calcFromRatio(24)), );
      @include responsive($property: width, $figma: (calcFromRatio(136)), );
    }

    .cardExpiry {
      background-color: #6B757E80;
      font-family: 'NotoSanJP';
      @include responsive($property: border-radius, $figma: (calcFromRatio(4)), );
      @include responsive($property: line-height, $figma: (calcFromRatio(12)), );
      @include responsive($property: padding, $figma: (calcFromRatio(6)), );
      @include responsive($property: height, $figma: (calcFromRatio(24)), );
      @include responsive($property: width, $figma: (calcFromRatio(64)), );
    }
  }

  .faceShadow {
    z-index: 2;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 3%;
    @include responsive($property: width, $figma: (calcFromRatio(242)), );
    @include responsive($property: height, $figma: (calcFromRatio(156)), );
  }

  .flipSide {
    position: absolute;
    z-index: 1;
    background-color: #161C1C;
    border-radius: 3%;
    right: 0;
    @include responsive($property: top, $figma: (calcFromRatio(18)), );
    @include responsive($property: width, $figma: (calcFromRatio(240)), );
    @include responsive($property: height, $figma: (calcFromRatio(154)), );
    @include responsive($property: padding, $figma: (calcFromRatio(16)), );

    .magneticLine {
      position: absolute;
      background-color: #2E3439;
      width: 100%;
      left: 0;
      @include responsive($property: top, $figma: (calcFromRatio(16)), );
      @include responsive($property: height, $figma: (calcFromRatio(24)), );
    }

    .cvcWrap {
      position: absolute;
      @include responsive($property: bottom, $figma: (calcFromRatio(34)), );
      @include responsive($property: right, $figma: (calcFromRatio(16)), );

      .inputLabel {
        color: #FFFFFF;
        font-weight: lighter;
        @include responsive($property: height, $figma: (calcFromRatio(12)), );
        @include responsive($property: line-height, $figma: (calcFromRatio(12)), );
        @include responsive($property: font-size, $figma: (calcFromRatio(12)), );
      }

      .cvc {
        background-color: #6B757E80;
        font-family: 'NotoSanJP';
        @include responsive($property: border-radius, $figma: (calcFromRatio(4)), );
        @include responsive($property: line-height, $figma: (calcFromRatio(16)), );
        @include responsive($property: padding, $figma: (calcFromRatio(4)), );
        @include responsive($property: height, $figma: (calcFromRatio(24)), );
        @include responsive($property: width, $figma: (calcFromRatio(64)), );
      }
    }
  }

  .help {
    z-index: 4;
    position: absolute;
    @include responsive($property: bottom, $figma: (calcFromRatio(58)), );
    @include responsive($property: right, $figma: (calcFromRatio(16)), );
    @include responsive($property: width, $figma: (calcFromRatio(12)), );
  }

  .helpPopUp {
    z-index: 5;
    border-radius: 3%;
    position: absolute;
    text-align: center;
    background-color: #FFFFFF;
    @include responsive($property: bottom, $figma: (calcFromRatio(72)), );
    @include responsive($property: right, $figma: (calcFromRatio(16)), );
    @include responsive($property: width, $figma: (calcFromRatio(129)), );
    @include responsive($property: height, $figma: (calcFromRatio(54)), );
    @include responsive($property: padding, $figma: (calcFromRatio(4)), );

    p {
      @include responsive($property: height, $figma: (calcFromRatio(12)), );
      @include responsive($property: line-height, $figma: (calcFromRatio(12)), );
      @include responsive($property: font-size, $figma: (calcFromRatio(12)), );
    }

    img {
      display: block;
      margin: 0 auto;
      @include responsive($property: width, $figma: (calcFromRatio(40)), );
      @include responsive($property: margin-top, $figma: (calcFromRatio(4)), );
    }
  }
}

.stripeError{
  color: red;
  @include responsive($property: font-size, $figma: (16, 18, 20), );
  @include responsive($property: margin-top, $figma: (12, 14, 16), );
}
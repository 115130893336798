@import '../../zz_general/utils/scss-modules/mixin.module.scss';
@import '../kamaitachiCommon.module.scss';

.contactForm {
  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  margin: 0 auto;

  .H1Title {
    @include responsive(
      $property: font-size,
      $figma: (
        24,
        26,
        28,
      )
    );
    text-align: center;
    @include responsive(
      $property: padding,
      $figma: (
        8,
        9,
        10,
      )
    );
  }

  .pullDown {
    display: flex;
    border: 2px solid black;
    justify-content: space-between;
    margin-bottom: 2px;
    @include responsive(
      $property: padding,
      $figma: (
        8,
        9,
        10,
      )
    );
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
  }

  .pullDownText {
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
    @include responsive(
      $property: padding,
      $figma: (
        8,
        9,
        10,
      )
    );
  }

  .formFlex {
    display: flex;
    justify-content: space-between;
  }

  .textAreaTitle {
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
    @include responsive(
      $property: line-height,
      $figma: (
        40,
        42,
        42,
      )
    );
    @include responsive(
      $property: height,
      $figma: (
        40,
        42,
        42,
      )
    );
  }

  .textArea {
    display: block;
    width: 100%;
    @include responsive(
      $property: height,
      $figma: (
        200,
        220,
        240,
      )
    );
    border: 2px solid #c4c4c4;
    @include responsive(
      $property: border-radius,
      $figma: (
        4,
        6,
        8,
      )
    );
    @include responsive(
      $property: padding,
      $figma: (
        4,
        6,
        8,
      )
    );
    @include responsive(
      $property: margin-bottom,
      $figma: (
        16,
        18,
        20,
      )
    );
    @include responsive(
      $property: font-size,
      $figma: (
        18,
        20,
        22,
      )
    );
  }

  .checkBoxWrap {
    display: flex;
    align-items: flex-end;
    @include responsive(
      $property: font-size,
      $figma: (
        12,
        14,
        16,
      )
    );
    @include responsive(
      $property: line-height,
      $figma: (
        24,
        26,
        28,
      )
    );
    @include responsive(
      $property: margin-bottom,
      $figma: (
        16,
        18,
        20,
      )
    );

    .checkBox {
      display: block;
      @include responsive(
        $property: height,
        $figma: (
          24,
          26,
          28,
        )
      );
      @include responsive(
        $property: margin-right,
        $figma: (
          24,
          28,
          32,
        )
      );
    }
  }
}

@use '../../utils/LPCommon.module.scss';
@import '../../../zz_general/utils/scss-modules/mixin.module.scss';

.loadingContainer {
  font-family: 'KiwiMaru', 'NotoSanJP';
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  /* 画面の高さいっぱいに表示する */
  .loading {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #161c1c;
    border-radius: 50%;
    @include responsive(
      $property: width,
      $figma: (
        32,
        36,
        40,
      )
    );
    @include responsive(
      $property: height,
      $figma: (
        32,
        36,
        40,
      )
    );
    animation: spin 1s linear infinite;
  }

  .loadingText {
    @include responsive(
      $property: margin-left,
      $figma: (
        8,
        9,
        10,
      )
    );
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.reservations {
  @include responsive(
    $property: width,
    $figma: (
      340,
      512,
      720,
    )
  );
  margin: 0 auto;
  text-align: center;

  * {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  .message {
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
    line-height: 100%;
    text-align: left;
    @include responsive(
      $property: margin-left,
      $figma: (
        0,
        9,
        100,
      )
    );
    @include responsive(
      $property: margin-top,
      $figma: (
        16,
        18,
        20,
      )
    );
  }

  .line_login_button {
    position: relative; /* relativeに設定 */
    display: inline-block; /* 要素の幅に応じた中央揃え */
    margin: 16px auto 0 auto; /* 上に16px、横方向の中央揃え */
    left: 50%; /* 親要素の中央まで移動 */
    transform: translateX(-50%); /* 自分の幅の半分だけ左にずらす */

    .button_mask {
      position: absolute;
      width: 100%;
      height: 48px;
      background-color: #000000;
      opacity: 0;
      border-radius: 8px;
    }

    .button_mask:hover {
      opacity: 0.1;
    }

    .button_mask:active {
      opacity: 0.3;
    }
  }

  .line_login_text {
    margin-top: 16px;
    text-align: center;
    font-weight: 600;
    color: #000000;
    line-height: 100%;
  }

  .title {
    @include responsive(
      $property: font-size,
      $figma: (
        16,
        18,
        20,
      )
    );
    line-height: 100%;
    text-align: left;
  }

  .text {
    @include responsive(
      $property: font-size,
      $figma: (
        12,
        14,
        16,
      )
    );
    @include responsive(
      $property: line-height,
      $figma: (
        16,
        18,
        20,
      )
    );
  }

  .reservationsIdSection {
    .reservationsId {
      @include responsive(
        $property: font-size,
        $figma: (
          16,
          18,
          20,
        )
      );
      @include responsive(
        $property: margin-top,
        $figma: (
          16,
          18,
          20,
        )
      );
    }

    .reservationQr {
      margin: 0 auto;
      @include responsive(
        $property: margin-top,
        $figma: (
          12,
          14,
          16,
        )
      );
      @include responsive(
        $property: width,
        $figma: (
          200,
          200,
          200,
        )
      );
    }
  }

  .waitingCover {
    width: 400px;
    max-width: 80%;
    margin: 0 auto;
  }

  .waitingCoverImg {
    margin-top: 16px;
    display: block;
    width: 100%;
  }

  .reservationsContents {
    text-align: left;
    @include responsive(
      $property: margin-top,
      $figma: (
        24,
        40,
        64,
      )
    );
    @include responsive(
      $property: margin-bottom,
      $figma: (
        24,
        40,
        64,
      )
    );

    .lineS {
      border: 1px solid #c4c4c4;
    }

    .lineM {
      border: 1px solid #6b757e;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .cardNum {
      display: flex;
      @include responsive(
        $property: margin-top,
        $figma: (
          8,
          9,
          10,
        )
      );

      .cardIcon {
        // display: block;
        @include responsive(
          $property: height,
          $figma: (
            16,
            18,
            20,
          )
        );
        @include responsive(
          $property: margin-right,
          $figma: (
            16,
            18,
            20,
          )
        );
      }

      .cardFlex {
        display: flex;

        .cardBrand {
          display: block;
          @include responsive(
            $property: height,
            $figma: (
              24,
              28,
              32,
            )
          );
          @include responsive(
            $property: margin-right,
            $figma: (
              8,
              9,
              10,
            )
          );
          @include responsive(
            $property: margin-top,
            $figma: (
              8,
              9,
              10,
            )
          );
        }

        .cardText {
          display: block;
          @include responsive(
            $property: font-size,
            $figma: (
              12,
              14,
              16,
            )
          );
          @include responsive(
            $property: line-height,
            $figma: (
              24,
              28,
              32,
            )
          );
          @include responsive(
            $property: height,
            $figma: (
              24,
              28,
              32,
            )
          );
          @include responsive(
            $property: margin-top,
            $figma: (
              8,
              9,
              10,
            )
          );
        }
      }
    }

    .payPay {
      display: block;
      @include responsive(
        $property: margin-top,
        $figma: (
          8,
          9,
          10,
        )
      );
      @include responsive(
        $property: margin-left,
        $figma: (
          8,
          9,
          10,
        )
      );
      @include responsive(
        $property: height,
        $figma: (
          24,
          26,
          28,
        )
      );
    }

    .linkButton {
      @include responsive(
        $property: margin-top,
        $figma: (
          24,
          28,
          32,
        )
      );
    }
  }
}

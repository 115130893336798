body.active-modal {
  overflow-y: hidden;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal_content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 28px 28px;
  max-width: 600px;
  min-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  border: 4px solid #6b757e;
  background: #fff;
  color: #000;
  font-family: 'Kiwi Maru';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  .close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px; /* Adjust width as needed */
    height: 30px; /* Adjust height as needed */
    background: transparent;
    font-size: 30px;
    cursor: pointer;
    padding: 0;
    margin: 3px; /* Add margin to keep it away from the edges */
    border: none;
  }
}
.modal_text {
  text-align: left;
}
.sound_button {
  width: 80px;
  height: 80px;
  margin: 10px;
}

.button_group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 48px;
}

.button_group .sound_button {
  width: 120px;
  height: 48px;
  padding: 5px 20px;
  cursor: pointer;
  border: 4px solid #c4c4c4;
  background: #fff;
  border-radius: 16px;
  color: #c4c4c4;
  font-family: 'Noto Sans JP';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.button_group .sound_button.selected {
  background: #fff;
  color: #000;
  border-radius: 16px;
  border: 4px solid #000;
}

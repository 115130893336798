@use './zz_general/utils/scss-modules/common.module.scss';

.shippingPadding {
  padding: 0 24px;
}

.Confirm {
  .t1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
  }

  .t {
    font-size: 3.2vw;
    font-size: 20px;
    line-height: 24px;

    .text {
      color: #6b757e;
      padding-bottom: 64px;
      position: relative;
      margin-bottom: 16px;

      .line_login_text {
        margin-top: 16px;
        text-align: center;
        font-weight: 600;
        color: #000000;
        line-height: 100%;
      }

      .line_login_button {
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);

        .button_mask {
          position: absolute;
          width: 100%;
          height: 48px;
          background-color: #000000;
          opacity: 0;
          border-radius: 8px;
        }

        .button_mask:hover {
          opacity: 0.1;
        }

        .button_mask:active {
          opacity: 0.3;
        }
      }
    }
  }

  .t2 {
    font-size: 16px;
    line-height: 100%;
  }

  .lineS {
    border: 1px solid #c4c4c4;
  }

  .lineM {
    border: 1px solid #6b757e;
  }

  .thanks_icon {
    width: 24px;
  }
}

.Maintenance {
  // text-align: center;
  padding: 10vw;
  font-size: 24px;
}

.modalOpen {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.ItemPanel_imgCon {
  width: 100%;
  height: 0px;
  padding-bottom: 66.67%;
  overflow: hidden;
  position: relative;
  margin-bottom: clamp(8px, 2.13vw, 16px);

  &Matcha {
    background-color: #264435;
    color: #ffffff;
  }

  .ItemPanel_img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .purchaseLimitText {
    color: white;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 100%;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    z-index: 8;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 899.9px) {
      min-width: 250px;
      font-size: 25px;
    }
    @media (min-width: 1200px) {
      min-width: 250px;
      font-size: 25px;
    }
  }

  .biancoNeroPurchaseLimitText {
    color: white;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 100%;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    z-index: 20;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 899.9px) {
      min-width: 250px;
      font-size: 25px;
    }
    @media (min-width: 1200px) {
      min-width: 250px;
      font-size: 25px;
    }
  }

  .rightVector {
    position: absolute;
    z-index: 5;
    right: 3%;
    width: 7%;
    top: 50%;
    transform: translate(0, -50%);
  }

  .leftVector {
    position: absolute;
    z-index: 5;
    left: 3%;
    width: 7%;
    top: 50%;
    transform: translate(0, -50%);
  }

  .valentine2 {
    position: relative;

    .valentine_wrap2 {
      position: absolute;
      width: 120%;
      margin-top: -10%;
      margin-left: -10%;
      z-index: 5;
    }
  }
}

.ItemPanel_flex1 {
  display: flex;
  justify-content: space-between;
  position: relative;

  .displayCountCon {
    display: flex;
    align-items: center;
    margin-top: 2px;
    .displayCount {
      font-size: 14px;
    }
  }

  .removebuttonCon {
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
}

// cartでも使うため外に出した
.addbuttonCon {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.addbutton {
  height: 19px;
  width: 19px;
}

.ItemPanel_flex2 {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.ItemPanelSetContents {
  font-family: 'KiwiMaru';
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
}

.setContentsText {
  font-family: 'KiwiMaru';
  margin-top: 4px;
  line-height: 20px;
}

.CartModal {
  width: 80vw;
  height: 80vh;

  z-index: 10;
  font-family: 'KiwiMaru', 'NotoSanJP';
  font-weight: 700;
  color: #161c1c;
  background-color: #ffffff;
  max-width: 600px;
  width: 80vw;
  max-height: clamp(64vh, calc(61.81vw + 228.18px), 90vh);
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px;

  .ModalBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Modal {
    z-index: 10;
    font-family: 'KiwiMaru', 'NotoSanJP';
    color: #161c1c;
    background-color: #ffffff;
    max-width: 600px;
    width: 80vw;
    max-height: clamp(64vh, calc(61.81vw + 228.18px), 90vh);
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 4px;
    overflow-y: scroll;

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    position: fixed;

    .topContent {
      height: 80px;
      display: flex;
      align-items: center;

      button {
        margin: 24px;
      }

      .cartLogo {
        // font-weight: 400;
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }
    }

    .bottomContent {
      width: 100%;
      padding: 16px 0;
      background-color: #f3f4f5;
      position: relative;

      .fee {
        background-color: #ffffff;
        padding: 16px 24px;

        .postage {
          text-align: end;

          font-size: 24px;
          margin-right: 22px;

          .span1 {
            font-family: 'KiwiMaru';
            font-size: 12px;
            font-weight: 100;
          }

          .span2 {
            font-family: 'KiwiMaru';
            font-size: 16px;
          }

          .span3 {
            font-family: 'KiwiMaru';
            font-size: 24px;
          }

          .span4 {
            font-family: 'KiwiMaru';
            font-size: 12px;
            font-weight: 100;
          }
        }

        .cart_icon {
          @media (max-width: 899.9px) {
            width: 30px;
            height: auto;
          }

          @media (max-width: 260px) {
          }
        }

        .priceFont {
          font-family: 'KiwiMaru';
          font-size: 12px;
          margin-right: 4px;
          color: #6b757e;
        }

        .priceFont2 {
          font-family: 'NotoSanJP';
          color: #22292f;
          font-size: 24px;
          margin-bottom: -2px;
        }

        .priceFont3 {
          font-family: 'NotoSanJP';
          font-size: 32px;
          color: #22292f;
          margin-right: 4px;
          margin-bottom: -6px;
        }

        .priceFont4 {
          font-family: 'KiwiMaru';
          font-size: 12px;
          margin-right: 4px;
          color: #22292f;
        }
      }

      .addTextBox {
        background-color: #f3f4f5;
        font-size: 12px;
        padding: 16px 0;
        text-align: center;
        justify-content: space-between;
      }

      .purchase {
        padding: 0 22px;

        .continue {
          border: solid 1px #000000;
          border-radius: 4px;
        }
      }

      .upSellBox {
        position: relative;
        width: 100%;

        &.open {
          height: 100%;
        }

        &.close {
          max-height: 125px;
          // background-image: linear-gradient(
          //   0deg,
          //   rgba(255, 255, 255, 1),
          //   rgba(255, 255, 255, 0.64) 30%,
          //   rgba(255, 255, 255, 0)
          // );
        }

        .upCellItems {
          overflow: hidden;

          &.open {
            height: 100%;
          }

          &.close {
            max-height: 170px;
          }

          .upCellItem {
            display: flex;
            justify-content: space-between;
            background-color: #ffffff;
            height: 85px;
            padding-top: 16px;
            padding-bottom: 8px;
            padding-left: 8px;
            margin-right: 22px;
            margin-left: 22px;
            margin-bottom: 16px;
            border-radius: 6px;

            &:last-child {
              margin-bottom: 0;
            }

            .textBox {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 50%;

              @media (max-width: 899.9px) {
                width: 70%;
              }

              .name {
                font-size: clamp(10px, calc(1.14vw + 7.71px), 20px);
              }

              .price {
                font-size: 16px;
                font-weight: bold;
              }
            }

            .box {
              display: flex;
              justify-content: flex-end;
              width: 50%;
              padding-right: 8px;

              .imgBox {
                margin-right: 20px;
                position: relative;
                width: 150px;
                height: 100px;
                overflow: hidden;

                @media (max-width: 899.9px) {
                  height: 67px;
                  width: 101px;
                  margin-right: 0px;
                }
              }

              .img {
                max-width: 100%;
                height: auto;
              }

              .addTextBox {
                align-self: center;
                width: 50%;
                background-color: #ffffff;

                @media (max-width: 899.9px) {
                  display: none;
                }

                .text {
                  color: white;
                  background-color: #161c1c;
                  margin: 0 5px;
                  padding: 10px;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }

      .isSetShow {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff 60%);
        position: relative;
        text-align: center;
        padding-top: 35px;
        padding-bottom: 18px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .choose {
      padding: 0 22px;
    }
  }

  .cartContent {
    width: 100%;
    text-align: center;
    background-color: #f3f4f5;
    padding-top: 12px;
    padding-bottom: 0;
    overflow: scroll;

    &.items {
      height: 100%;
    }

    .cartLogo {
      // font-weight: 400;
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    .CartItem {
      background-color: #ffffff;
      display: flex;
      border-radius: 6px;
      justify-content: space-between;
      font-weight: 400;
      margin-right: 24px;
      margin-left: 24px;
      margin-bottom: 16px;
      padding-top: 8px;
      padding-right: 8px;
      padding-left: 8px;
      padding-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .item_font1 {
        text-align: left;
        font-size: clamp(10px, calc(1.14vw + 7.71px), 20px);
        font-weight: bold;
      }

      .item_font2 {
        text-align: left;
        font-weight: bold;
        font-size: clamp(14px, calc(0.4vw + 14.57px), 20px);

        @media (max-width: 899.9px) {
          position: absolute;
          bottom: clamp(0px, calc(5.71vw - 21.43px), 30px);
        }

        @media (min-width: 900px) {
          padding-top: 18px;
        }
      }

      .item_img_con1 {
        margin: auto;
        position: relative;
        height: 67px;
        width: 101px;
        max-width: 150px;
        max-height: 100px;
        overflow: hidden;

        @media (min-width: 900px) {
          display: none;
        }

        .item_img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .item_img_con2 {
        @media (max-width: 899.9px) {
          display: none;
        }

        margin-right: 20px;
        position: relative;
        width: 150px;
        height: 100px;
        overflow: hidden;

        .item_img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .buttons {
        margin: auto;
        display: flex;
        justify-content: space-around;

        @media (max-width: 899.9px) {
          width: 100px;
        }
      }

      .buttonsContent {
        margin-top: 8px;
        display: flex;
        align-items: center;
        width: 100px;
        justify-content: space-between;

        .buttonsBox {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
          }
        }
      }

      .ul {
        margin: auto;
        display: flex;
        justify-content: space-around;

        @media (max-width: 899.9px) {
          max-width: 150px;
        }
      }
    }
  }
}

.menu {
  text-align: center;
  background-color: grey;
  padding: 6px 6px;
  overflow: scroll;
}

.transactionAct {
  padding: 32px;
  padding-bottom: calc(9vw + 88px);
  color: #161c1c;

  .transactionAct_title {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 700;
  }

  .transactionAct_element {
    margin-bottom: 19px;
    font-size: 16px;
    font-weight: 700;
  }

  .transactionAct_text {
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
  }

  .rectangle {
    background-color: #c4c4c4;
    color: #c4c4c4;
    width: 8.53vw;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -32px;
  }
}

.Policy {
  // position: relative;
  *:focus {
    outline: none;
  }

  .Policy_Index {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .Policy_Index_Header {
      display: flex;
      justify-content: space-between;
    }

    .Policy_Index_1 {
      background-color: #161c1c;
      color: #ffffff;
      padding: 32px;
      width: 236px;
      height: 80vh;
      overflow: scroll;
    }

    .Policy_Index_2 {
      background-color: #161c1c;
      color: #ffffff;
      padding: 32px;
      width: 236px;
      height: 80vh;
      overflow: scroll;
    }
  }

  .header_container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    background-color: #ffffff;

    .Policy_Header {
      display: flex;
      justify-content: space-between;
    }

    .Policy_Tabs {
      text-align: center;
      height: 78px;
      display: flex;
      border-bottom: solid 2px #161c1c;

      .Policy_Tab_1 {
        background-color: #ffffff;
        margin-left: 6.4vw;
        margin-top: 30px;
        width: 28.53vw;
        height: 48px;
        line-height: 48px;
        border: solid 2px #161c1c;
        border-bottom: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 9;
      }

      .Policy_Tab_2 {
        background-color: #ffffff;
        margin-top: 30px;
        width: 57.6vw;
        height: 48px;
        line-height: 48px;
        border: solid 2px #161c1c;
        border-bottom: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 9;
      }

      .deactivate1 {
        background-color: #f3f4f5;
        margin-top: 30px;
        width: 28.53vw;
        height: 47px;
        line-height: 48px;
        margin-left: 6.4vw;
      }

      .deactivate2 {
        background-color: #f3f4f5;
        margin-top: 30px;
        width: 57.6vw;
        height: 47px;
        line-height: 48px;
      }
    }
  }

  .Policy_Container {
    margin-top: calc(184px - 8vw);

    .Policy_Body {
      .Policy_Body_1 {
        padding: 8vw;
        padding-bottom: calc(9vw + 88px);
        // overflow: scroll;
      }

      .Policy_Body_2 {
        padding: 8vw;
        padding-bottom: calc(9vw + 88px);
        // overflow: scroll;
      }

      .Policy_Body_SubTitle {
        font-weight: 700;
        margin-bottom: 16px;
      }

      .Policy_Body_Text {
        margin-bottom: 16px;
      }

      .Policy_Body_GrayText {
        padding: 8vw;
        background-color: #f3f4f5;
        margin-bottom: 16px;
        font-weight: bold;
      }
    }
  }

  .Policy_Img1 {
    margin-top: 22px;
    margin-right: 5.87vw;
    width: 18px;
  }

  .Policy_Img2 {
    margin-top: 22px;
    margin-left: 5.87vw;
    width: 18px;
  }

  .Policy_Img3 {
    width: 18px;
  }

  a.anchor {
    display: block;
    padding-top: 30px;
    margin-top: -30px;
  }
}

.Policy_and_Transaction_footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: calc(9vw + 88px);
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(1px);

  // background-color: rgba(25, 25, 25, 0.4);
  .pageTop_Button {
    position: fixed;
    right: 8vw;
    bottom: 8vw;
    text-align: center;
    width: 88px;
    height: 88px;
    color: #161c1c;
    background-color: #ffffff;
    border-radius: 50%;
    border: solid 1px #161c1c;
    font-weight: bold;
  }

  .return_button {
    font-weight: bold;
    text-align: center;
    width: 110px;
    height: 40px;
    line-height: 40px;
    margin-top: 300px;
    position: fixed;
    bottom: 8vw;
    left: 8vw;
    color: #161c1c;
    background-color: #ffffff;
    border: solid 1px #161c1c;
    border-radius: 4px;
  }
}

.input_info_con {
  @media (max-width: 833.99px) {
    padding-right: clamp(0px, calc(17.43vw - 65.36px), calc(17.43vw - 65.36px));
    padding-left: clamp(0px, calc(17.43vw - 65.36px), calc(17.43vw - 65.36px));
  }

  @media (min-width: 834px) {
    padding-right: clamp(0px, calc(13.2vw - 30.1px), calc(13.2vw - 30.1px));
    padding-left: clamp(0px, calc(13.2vw - 30.1px), calc(13.2vw - 30.1px));
  }

  padding-top: clamp(0px, calc(3.34vw - 20px), 28px);
  padding-bottom: clamp(12px, calc(3.34vw - 8px), 40px);
}

.address_edit {
  position: relative;
  .edit_button_on {
    width: 40px;
    position: absolute;
    right: -8px;
    top: -3px;
    color: #ff5656;
    font-size: 20px;
  }
}

.calender_alert {
  //配送日指定無化のため
  color: red;

  p {
    font-weight: bold;
  }
}

.calender_mask {
  background-color: #22292f; //配送日指定無効化のため
}

.calender_br {
  margin-right: clamp(0px, calc(23.47vw - 88.03px), calc(23.47vw - 88.03px));
  margin-left: clamp(0px, calc(23.47vw - 88.03px), calc(23.47vw - 88.03px));
  margin-top: 2.13vw;
  padding: 3.2%;
  border: solid 1px #c4c4c4;
  border-radius: 4px;

  .title {
    text-align: center;
  }

  .days_of_week {
    display: flex;
    flex-wrap: wrap;
    height: 0px;
    padding-bottom: 14.28%;

    .day_of_week {
      text-align: center;
      width: 14.28%;
      margin-top: calc(7.14% - 8px);
    }
  }

  .date_icons {
    display: flex;
    flex-wrap: wrap;
  }
}

.date_iconCon {
  width: 14.28%;
  height: 0px;
  padding-bottom: 14.28%;
  position: relative;

  .date_icon {
    @media (max-width: 833.99px) {
      --padding-left: clamp(0px, calc(17.43vw - 65.36px), calc(17.43vw - 65.36px));
    }

    @media (min-width: 834px) {
      --padding-left: clamp(0px, calc(13.2vw - 30.1px), calc(13.2vw - 30.1px));
    }

    --margin-left: clamp(0px, calc(23.47vw - 88.03px), calc(23.47vw - 88.03px));
    --calender_width: calc(100vw - calc(var(--padding-left) + 24px + var(--margin-left)) * 2);
    --height: calc(var(--calender_width) * 0.0853);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    // line-height: 32px;
    width: var(--height);
    height: calc(var(--height) - 2px);

    // border: solid 1px #161c1c;
    // border-radius: 50%;
    .in {
      border: solid 1px #161c1c;
      border-radius: 50%;
      line-height: calc(var(--height) - 2px);
    }

    .in:active {
      background-color: gray;
      color: #ffffff;
    }

    .out {
      color: #c4c4c4;
      line-height: calc(var(--height) - 2px);
    }

    .selected {
      background-color: #161c1c;
      color: #ffffff;
    }

    .not {
      color: #161c1c;
    }
  }
}

.input_box {
  position: relative;

  .border_red {
    border: solid 1px red;
    border-radius: 4px;
    padding-right: 2px;
    padding-left: 0.5px;
    padding-bottom: 0.5px;
  }

  .tick {
    position: absolute;
    right: 12px;
    top: 24px;
  }

  .cross {
    position: absolute;
    right: 12px;
    top: 24px;
  }

  .half {
    margin-left: 0;
    width: calc(50% - 24px);
  }

  .tick_half {
    position: absolute;
    right: calc(50% + 32px);
    top: 24px;
  }

  .cross_half {
    position: absolute;
    right: calc(50% + 32px);
    top: 24px;
  }
}

.paymentErrMessage {
  background-color: #fbece9;
  border: solid 1px #ff5656;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin: 0 8px;
  }

  p {
    font-size: 16px;
    color: #ff5656;
    margin: 14px 0;
  }
}

.card_img {
  position: absolute;
  left: 24px;
  top: 18px;
  width: 48px;
}

.disabledTrue {
  background-color: gray !important;
  border: solid 1px gray !important;

  &:hover {
    border: solid 1px gray !important;
  }

  button {
    color: white !important;
  }
}

.overflowWrap {
  overflow-wrap: anywhere;
}

.formHeaderInner {
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  color: #161c1c;
  padding: 0 24px;

  @media (min-width: 834px) {
    font-size: 32px;
  }

  img {
    width: 32px;
    height: 32px;
  }
}

.cartReturn {
  border: solid 1px #000000;
  border-radius: 4px;
}

.helpMark {
  display: inline;
  vertical-align: top;
  position: relative;

  .textBox1Img {
    width: 20px;
    height: 20px;
    margin-left: 3px;
    margin-top: -2px;
    margin-bottom: -4px;
  }

  .textBox2Img {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }

  .textBox1 {
    position: absolute;
    top: -1px;
    width: 14rem;
    left: 6px;
  }

  .textBox2 {
    position: absolute;
    top: -1px;
    width: 18rem;
    left: 8px;
    margin-bottom: 2px;
  }

  .textBox3 {
    position: absolute;
    top: 3px;
    width: 16rem;
    left: 6px;
  }

  p {
    display: inline;
    background-color: #c4c4c4;
    padding: 4px;
    border-radius: 10px;
    font-size: 12px;
  }

  .textVisible {
    display: inline;
  }

  .textUnVisible {
    display: inline;
  }
}

.itemPanelCon {
  --width: 100%;
  @media (min-width: 900px) {
    --padding: clamp(24px, calc(20.28vw - 52.06px), 240px);
    --width: calc(50vw - var(--padding) - 120px);
    width: var(--width);
    margin-left: 20px;
    margin-right: 20px;
  }
  padding: calc(var(--width) * 0.075);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  @media (max-width: 899.9px) {
    margin-bottom: clamp(24px, 6.4vw, 40px);
  }

  &Nero {
    background-color: #161c1c;
    color: #ffffff;
  }

  &Matcha {
    background-color: #264435;
    color: #ffffff;
  }
  &Split {
    background: linear-gradient(90deg, white 0%, white 50%, #161c1c 50%, #161c1c 100%);
    // background: rgb(0, 0, 0); //白背景
    position: relative;
    .splitContent {
      .split {
        text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff,
          -1px 0 0 #fff, 1px 0 0 #fff;
      }
    }

    .displayCount {
      color: #ffffff;
    }
  }
}

.adjustZIndex {
  z-index: 7;
}

.menu {
  width: 100vw;
  height: 100vh;
}

.loader {
  margin-left: 5%;
  color: #161c1c;
  font-size: 20px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
.loaderWhite {
  margin-left: 5%;
  color: white;
  font-size: 20px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.zIndex10 {
  z-index: 10;
}

@use '../index.module.scss';
@import '../zz_general/utils/scss-modules/mixin.module.scss';

.secret {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'KiwiMaru', 'NotoSanJP';
  background-color: #4b3930;
  color: #ffffff;
  padding: 10vw;
  min-height: 100vh;

  .questionWrap {
    opacity: 0;
    animation: fadeIn 4s forwards;

    .instruction {
      margin: 0 0 4vw;
      @include responsive($property: font-size, $figma: (18, 24, 24), );
      @include responsive($property: margin-top, $figma: (9, 24, 24), );
    }

    .question {
      margin: 4vw 0 0;
      @include responsive($property: padding, $figma: (24, 40, 64), );
      background-color: white;
      color: black;
      border-radius: 8px;
      @include responsive($property: font-size, $figma: (24, 36, 40), );
      @include responsive($property: line-height, $figma: (24, 36, 40), );

      .copyButtonCon {
        background-color: white;
        text-align: right;

        .copyButton {
          @include responsive($property: margin-top, $figma: (8, 16, 16), );
          font-family: 'KiwiMaru', 'NotoSanJP';
          border-radius: 4px;
          @include responsive($property: padding, $figma: (8, 16, 16), );
          @include responsive($property: font-size, $figma: (14, 20, 28), );
          @include responsive($property: line-height, $figma: (14, 20, 28), );
        }
      }
    }



    .inputBox {
      box-sizing: border-box;
      font-family: 'KiwiMaru', 'NotoSanJP';
      margin: 0;
      padding: 4vw;
      @include responsive($property: padding-top, $figma: (16, 24, 32), );
      @include responsive($property: padding-bottom, $figma: (16, 24, 32), );
      @include responsive($property: font-size, $figma: (18, 24, 24), );
      border-radius: 8px;
      width: 100%;
      background-color: white;
      color: black;
      border: 0px;
    }

    .sendButton {
      font-family: 'KiwiMaru', 'NotoSanJP';
      padding: 4vw;
      @include responsive($property: padding-top, $figma: (16, 24, 32), );
      @include responsive($property: padding-bottom, $figma: (16, 24, 32), );
      @include responsive($property: font-size, $figma: (18, 24, 24), );
      border-radius: 8px;
      width: 100%;
    }

    .sendButtonOff {
      pointer-events: none;
      background-color: gray !important;
      border-color: gray !important;
    }

    .result {
      position: fixed;
      @include responsive($property: width, $figma: (320, 400, 400), );
      @include responsive($property: height, $figma: (384, 480, 480), );
      border-radius: 8px;
      border: solid 2px black;
      background-color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .crossIcon {
        cursor: pointer;
        background-color: white;
        color: black;
        position: absolute;
        right: 8%;
        top: 4%;
        @include responsive($property: font-size, $figma: (24, 28, 28), );
        z-index: 2;
      }

      h1 {
        z-index: 1;
        padding: 4%;
        background-color: white;
        color: black;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include responsive($property: font-size, $figma: (20, 24, 24), );
      }
    }

    .correct {
      h1 {
        .copyButtonCon {
          background-color: white;
          text-align: right;

          .copyButton {
            font-family: 'KiwiMaru', 'NotoSanJP';
            border-radius: 4px;
            @include responsive($property: padding, $figma: (8, 16, 16), );
            @include responsive($property: font-size, $figma: (18, 20, 20), );
            @include responsive($property: line-height, $figma: (18, 20, 20), );
          }
        }
      }
    }

    .mistake {
      h1 {
        .hint {
          width: 100%;
          text-align: left;

          span {
            width: 100%;
          }
        }
      }
    }
  }

  .button {
    color: white;
    background-color: black;
    border: solid 2px black;
    cursor: pointer;
  }

  .button:active {
    background-color: white;
    color: black;
    border: solid 2px black;
  }
}

.secretAnimation {
  .animationText {
    opacity: 0;
    animation: fadeIn 2s forwards;
    @include responsive($property: font-size, $figma: (20, 28, 32), );
  }
}

.animateSlowly {
  opacity: 0;
  display: inline-block;
  animation: fadeIn 0.5s forwards;
  @include responsive($property: font-size, $figma: (32, 36, 40), );
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
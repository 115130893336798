.management {
  .screen1 {
    padding: 10vw;

    .login_button {
      background-color: #000000;
      width: 50vw;
      padding: 2vw;
      border-radius: 4px;
      text-align: center;
      margin: auto;
      color: white;
      margin-top: 10px;
    }
  }
  .screen2 {
    .header {
      height: 12vh;
      background-color: #000000;
      color: white;
      display: flex;
      position: fixed;
      top: 0;
      width: 100vw;
      .logoImage {
        margin: 4vh 2vh 4vh 4vh;
        height: 4vh;
      }
      .logoText {
        margin: 4vh 4vh 4vh 2vh;
        height: 4vh;
      }
    }
    .tabs {
      height: 100vw;
      position: fixed;
      left: 0;
      border: 1px solid black;
      padding: 10px;
      .tab {
        padding: 16px;
        border-bottom: 1px solid #000000;
      }
      .tab:hover {
        background-color: rgb(200, 200, 200);
      }
      .logout_button {
        position: fixed;
        bottom: 10vw;
      }
    }
    .contents {
      margin-left: 200px;
      margin-top: 12vh;
      background-color: rgb(240, 240, 240);
      top: 0;
      .customer {
        padding: 10px;
        .customer_list {
          margin-top: 10px;
          margin-left: 10px;
          .list_element {
            display: flex;
            .customer_id {
              width: 50%;
              border: solid 1px black;
            }
            .name {
              width: 40%;
              border: solid 1px black;
            }
          }
        }
      }
    }
  }
}

.managementOrder {
  padding: 32px;
  .titleAndLinks {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .iconAndTitle {
      display: flex;
      .icon {
        height: 32px;
      }
      .title {
        font-size: 24px;
        line-height: 100%;
        height: 24px;
        padding: 4px 8px;
      }
    }
    .links {
      display: flex;
      justify-content: space-between;
      .ybm {
        height: 16px;
        line-height: 100%;
        padding: 8px 16px;
        background-color: #fccf00;
        border-radius: 4px;
      }
      .register {
        height: 16px;
        line-height: 100%;
        padding: 8px 16px;
        background-color: #161c1c;
        border-radius: 4px;
        margin-left: 16px;
        color: #fccf00;
      }
      .batchCreation {
        font-size: 16px;
        height: 16px;
        line-height: 100%;
        padding: 8px 16px;
        background-color: #ffffff;
        border-radius: 4px;
        margin-left: 16px;
        color: #3eb370;
      }
      .newCreation {
        height: 16px;
        line-height: 100%;
        padding: 8px 16px;
        background-color: #3eb370;
        border-radius: 4px;
        margin-left: 16px;
        color: #ffffff;
      }
    }
  }
  .search {
    background-color: #ffffff;
    border: solid 1px black;
    border-radius: 4px;
    .searchTitle {
      padding: 16px;
      border-bottom: solid 1px black;
    }
    .searchCriterias {
      padding-top: 16px;
      padding: 16px;
      border-bottom: solid 1px black;
      .searchCriteriasFlex {
        display: flex;
        .searchCriteria {
          margin-right: 16px;
          .dateTimeInput {
            width: 260px;
          }
        }
        .performSearch {
          background-color: #000000;
          color: #ffffff;
          border-radius: 4px;
          line-height: 100%;
          height: 16px;
          padding: 8px 16px;
        }
        .performSearch:hover {
          background-color: #565656;
        }
        .deleteAll {
          border-radius: 4px;
          line-height: 100%;
          height: 16px;
          padding: 7px 16px;
          border: solid 1px black;
          margin-right: 16px;
        }
      }
    }
  }
  .customer_list {
    margin-top: 32px;
    background-color: #ffffff;
    border-radius: 4px;
    border: solid 1px #000000;
    .list_element {
      display: flex;
      .customer_id {
        width: 50%;
        border: solid 1px black;
        text-align: center;
      }
      .name {
        width: 40%;
        border: solid 1px black;
        text-align: center;
      }
    }
  }

  .xls_export_button {
    margin-top: 10px;
    padding: 10px;
    border: solid 1px black;
    background-color: white;
  }
}

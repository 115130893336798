@use '../../zz_general/utils/scss-modules/common.module.scss';
@use '../kamaitachiCommon.module.scss';
@import '../../zz_general/utils/scss-modules/mixin.module.scss';

.topSection {
  .imageBox {
    margin: 0 auto;

    .topImage {
      width: 100%;
    }

    @media (min-width: 899.9px) {
      display: none;
    }
  }

  .imageBoxPC {
    @media (min-width: 899.9px) {
      display: block;

      .topImage {
        width: 100%;
      }
    }
  }

  .textBox {
    text-align: center;

    .title {
      font-size: clamp(24px, 2.9vw, 36px);
      margin-top: 24px;
    }

    .description {
      font-size: clamp(16px, 2.9vw, 24px);
      margin-top: 24px;
    }

    .attention {
      margin-top: 32px;
      @include responsive($property: font-size,
        $figma: (22,
          28,
          28,
        ));
    }
  }
}

.youtube_embed {
  max-width: 560px;
  margin: 24px auto;

  iframe {
    border: unset;
    width: 100%;
    height: auto;
    aspect-ratio: 560 / 315;
  }
}

.isDeliverySection {
  display: flex;
  height: 48px;
  font-size: 24px;
  margin-top: 38px;
  padding-top: 16px;

  border-top: solid 1px #000000;

  button {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(24px, 2.9vw, 36px);
    font-family: 'kiwiMaru', "noto sans jp", sans-serif;

    .icon {
      width: 30px;
      height: auto;
      margin-right: 8px;
    }
  }

  .adaptation {
    border-bottom: solid 3px #000000;
    color: rgba(0, 0, 0, 1);

    .icon {
      // filter: invert(50%) sepia(0%) saturate(11%) hue-rotate(143deg) brightness(101%) contrast(93%);
    }
  }

  .maladaptive {
    color: #c4c4c4;

    .icon {
      filter: invert(50%) sepia(0%) saturate(11%) hue-rotate(143deg) brightness(101%) contrast(93%);
    }
  }
}

.itemSection {
  margin-bottom: 32px;

  .title {
    font-size: clamp(24px, 2.9vw, 36px);
    text-align: center;
    margin-top: 32px;
  }

  .oneDayChief {

    background-image: url("./img/welcomeImg.webp");
    font-size: clamp(20px, 2.8vw, 25px);
    height: 215px;
    padding: 0px 0 25px;
    background-size: 220px;
    background-repeat: no-repeat;
    background-position: bottom right;
    max-width: 380px;
    margin: 32px auto;

    @media (max-width: 760px) {
      height: 200px;
      background-size: 190px;
      padding-bottom: 5px;
      background-repeat: no-repeat;
      background-position: bottom right;
      max-width: 325px;
      margin: 32px auto;
    }
  }

  .delivery {
    margin-top: 32px;
    font-size: clamp(20px, 2.8vw, 25px);

    background-image: url("./img/welcomeImg.webp");
    height: 215px;
    padding: 0px 0 25px;
    background-size: 220px;
    background-repeat: no-repeat;
    background-position: bottom right;
    max-width: 380px;
    margin: 32px auto;

    @media (max-width: 760px) {
      height: 200px;
      background-size: 190px;
      padding-bottom: 5px;
      background-repeat: no-repeat;
      background-position: bottom right;
      max-width: 325px;
      margin: 32px auto;
    }
  }

  .shopInfo {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    img {
      display: block;
      height: clamp(32px, calc(2.9vw / 3 * 4), 48px);
    }

    div {
      font-size: clamp(16px, calc(2.9vw * 2 / 3), 24px);
      height: clamp(32px, calc(2.9vw / 3 * 4), 48px);
      line-height: clamp(32px, calc(2.9vw / 3 * 4), 48px);
      text-align: center;
    }
  }

  .marker {
    background: linear-gradient(transparent 80%, #fff8ba 20%);
  }

  .itemBoxSingle {
    width: 100%;
    margin-top: 32px;
  }

  .itemBoxDouble {
    display: flex;
    width: 100%;
    margin-top: 32px;

    .ItemPanel {
      width: 50%;
    }

    :nth-child(2) {
      margin-left: 10px;
    }
  }
}

.copyRightSection {
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  padding-top: 32px;
  padding-bottom: 32px;

  .textBox {
    width: 250px;
    margin: 0 auto;

    @media (min-width: 610px) {
      width: 340px;
    }

    @media (min-width: 899.9px) {
      width: 350px;
    }

    p {
      font-size: clamp(16px, 2.9vw, 24px);
      font-weight: bold;
    }
  }
}

.logoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 56px;

  :nth-child(2) {
    margin-left: 32px;
  }

  :nth-child(3) {
    margin-left: 32px;
  }

  img {
    width: auto;
    height: 40px;

    @media (min-width: 899.9px) {
      height: 100px;
    }
  }
}

.footer {
  margin-top: 65px;
  text-align: center;

  .textBox {
    font-size: clamp(15px, 2.8vw, 22px);
    padding-bottom: 16px;

    p {
      margin-top: 30px;
    }
  }
}

.copyright {
  // margin-top: 64px;
  font-size: 14px;
  text-align: center;
}

.footercont {
  max-width: 210px;
  margin: 0 auto;
  margin-right: 0;

  @media (min-width: 389px) {
    max-width: 240px;
  }

  @media (min-width: 760px) {
    max-width: unset;
  }
}

.footerSec {
  background-image: url("./img/oksignImg.webp");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 165px;

  @media (min-width: 389px) {
    background-image: url("./img/oksignImg.webp");
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: bottom left;
  }
}

.homeCartSection {
  font-family: 'KiwiMaru';

  font-size: clamp(24px, 6.4vw, 40px);
  line-height: 150%;
  letter-spacing: 0.1em;
  // margin: clamp(64px, 17.06vw, 80px) 0;
  position: relative;

  .cartImg {
    border-radius: 4px;
    bottom: clamp(64px, 17.06vw, 80px);
    position: fixed;
    right: 0;
    width: clamp(0px, 17.06vw, 100px);
    z-index: 9;
  }

  .cart_icon {
    @media (max-width: 899.9px) {
      width: 30px;
      height: auto;
    }

    @media (max-width: 260px) {}
  }

  .priceFont {
    font-family: 'NotoSanJP';
    font-size: 12px;
    margin-right: 4px;
    color: #6b757e;
  }

  .priceFont2 {
    font-family: 'NotoSanJP';
    color: #22292f;
    font-size: 24px;

    @media (max-width: 899.9px) {
      font-size: 18px;
    }
  }

  .priceFont3 {
    font-family: 'NotoSanJP';
    font-size: 36px;
    color: #22292f;
    margin-right: 4px;

    @media (max-width: 899.9px) {
      font-size: 24px;
    }
  }

  .priceFont4 {
    font-family: 'NotoSanJP';
    font-size: 12px;
    margin-right: 4px;
    color: #22292f;
  }
}

.marginForCountDown {
  @include responsive($property: height,
    $figma: (135,
      170,
      194,
    ));
}

.countDownWrap {
  width: 100vw;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  z-index: 10;

  text-align: center;
  background-color: black;
  color: white;
  @include responsive($property: padding-top,
    $figma: (16,
      18,
      20,
    ));
  @include responsive($property: padding-bottom,
    $figma: (16,
      18,
      20,
    ));

  .countDownText {
    @include responsive($property: font-size,
      $figma: (32,
        36,
        48,
      ));
    @include responsive($property: margin-bottom,
      $figma: (16,
        18,
        20,
      ));
  }
}

.shopInfoModal {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 80%;
  overflow: scroll;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #f3f4f5;
  @include responsive($property: width,
    $figma: (320,
      400,
      480,
    ));
  @include responsive($property: border-radius,
    $figma: (4,
      5,
      6,
    ));

  .shopTitleFlex {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    @include responsive($property: padding,
      $figma: (16,
        18,
        20,
      ));
    @include responsive($property: font-size,
      $figma: (16,
        18,
        20,
      ));
    @include responsive($property: margin-bottom,
      $figma: (16,
        18,
        20,
      ));
  }

  .shopTitle {
    @include responsive($property: font-size,
      $figma: (24,
        28,
        32,
      ));
  }

  .shop {
    background-color: #ffffff;
    @include responsive($property: margin,
      $figma: (16,
        18,
        20,
      ));
    @include responsive($property: padding,
      $figma: (16,
        18,
        20,
      ));
    @include responsive($property: font-size,
      $figma: (16,
        18,
        20,
      ));

    .shopMap {
      border: 0;
      outline: 0;
      box-sizing: border-box;
      display: block;
      margin: 0 auto;
      @include responsive($property: border-radius,
        $figma: (4,
          5,
          6,
        ));
      @include responsive($property: margin-top,
        $figma: (16,
          18,
          20,
        ));
      @include responsive($property: width,
        $figma: (240,
          320 - 32,
          320 - 32,
        ));
    }
  }
}